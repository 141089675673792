import request from '@/utils/requestV2'
const qs = require('qs')

// 上传素材或资质获取filelocation
export function uploadFile (data) {
  return request({
    url: '/ooh-order/v2/orderfile/uploadfile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取当前order尚未提交发起审批的所有素材和资质文件
export function listDraftFile (data) {
  return request({
    url: '/ooh-order/v2/orderfile/list-draftfile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除订单下的file
export function deleteFile (data) {
  return request({
    url: '/ooh-order/v2/orderfile/delete-file',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 提交本订单下的所有草稿文件并发起审批(如果是撤回审批重新提交，则需要传递approvalid,默认会把草稿和所有已发起过的一起重新发起审批)
export function submit (data) {
  return request({
    url: '/ooh-order/v2/orderfile/submit',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 客户素材审批列表
export function approvalfilelist (data) {
  return request({
    url: '/ooh-order/om/v1/orderfile/approvalfilelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 根据审批id获取审批内容详情含画面信息
export function getapprovalinfo (data) {
  return request({
    url: '/ooh-order/om/v1/orderfile/getapprovalinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 已审批目录下 新增素材
export function addfile (data) {
  return request({
    url: '/ooh-order/om/v1/orderfile/addfile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取订单画面审批类型
export function getOrderProcessType (data) {
  return request({
    url: '/ooh-order/v1/orderfile/getOrderProcessType',
    method: 'get',
    params: data
  })
}

// 获取画面审批溯源信息
export function getOrderfileApprovalPage (data) {
  return request({
    url: '/ooh-order/v2/orderfile/getOrderfileApprovalPage',
    method: 'get',
    params: data
  })
}
